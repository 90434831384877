import Image from "next/image";
import googleLogin from "../images/login/googleLogin.png";
import miniLogo from "../images/login/miniLogo.png";
import Ellipse2 from "../images/login/Ellipse 2.png";
import Ellipse3 from "../images/login/Ellipse 3.png";
import Vector1 from "../images/login/Vector 1.png";
import Vector2 from "../images/login/Vector 2.png";
import Link from "next/link";
const Login = () => {
  return (
    <>
      <section className="pt-[60px] pb-[60px] sm:pt-[100px] sm:pb-[100px] lg:pt-[120px] lg:pb-[120px]">
        <div className="innerDiv   ">
          <div className="flex ">
            <div className="hidden relative overflow-hidden lg:flex pl-16 pr-0 flex-col gap-4 items-start justify-center bg-[#202020] basis-[55%]">
              <div className="circleAnimation absolute top-32 left-24 ">
                <Image alt="" src={Ellipse3}></Image>
              </div>
              <div className="circleAnimation2 absolute bottom-32 right-28 ">
                <Image alt="" src={Ellipse2}></Image>
              </div>
              <div className="vectiorAnimation w-full z-0 absolute top-0 left-0">
                <Image alt="" src={Vector2}></Image>
              </div>
              <div className="vectiorAnimation2 w-full absolute bottom-0 left-0">
                <Image alt="" src={Vector1}></Image>
              </div>
              {/* <Image alt='' src={Vector1}></Image> */}
              <h1 className="font-semibold z-20 text-white text-4xl leading-snug">
                Ready to learn with <br /> Gurucool ?
              </h1>
              <p className="text-white">
                Login to your account to resume from where you left
              </p>
            </div>
            <div className="flex flex-col m-auto items-center justify-center max-w-[670px] w-full lg:border-l-0 sm:border-2 sm:border-lighter-grey sm:px-16 sm:py-8  lg:basis-1/2">
              <div className="flex justify-between items-center sm:w-full mb-10 ">
                <h1 className="text-3xl font-medium ">Login</h1>
                <div className="hidden sm:block">
                  <Image alt="" src={miniLogo} objectFit="contain"></Image>
                </div>
              </div>
                <a className="flex gap-2 justify-center sm:w-full bg-white py-4 px-10 mb-8 rounded-full border-2 border-lighter-grey">
                  <Image
                    alt=""
                    src={googleLogin}
                    objectFit="contain"
                    width="30px"
                  ></Image>
                  <h2 className="text-sm font-medium"> Sign in with Google</h2>
                </a>
              <div className="flex items-center justify-center w-full mb-6">
                <div className="bg-lighter-grey h-[1px]  flex-1 "> </div>
                <p className="text-[#939393] px-4">or</p>
                <div className="bg-lighter-grey h-[1px]  flex-1 "> </div>
              </div>

              <form className="flex mb-8 flex-col items-start justify-center w-full">
                <div className="mb-10 flex flex-col w-full">
                  <label htmlFor="email" className="text-md font-semibold mb-4">
                    Email or username <span className="text-primary">*</span>
                  </label>
                  <input
                    type="email"
                    name=""
                    id="email"
                    placeholder="someone@something.com or username"
                    className="bg-[#F5F5F5] text-sm w-full py-4 px-4 outline-none rounded-full focus:ring-primary focus:ring-2 "
                  />
                </div>
                <div className="mb-10 flex flex-col w-full">
                  <label
                    htmlFor="password"
                    className="text-md font-semibold inline-block mb-4"
                  >
                    Password <span className="text-primary ">*</span>
                  </label>
                  <input
                    type="password"
                    name=""
                    id="password"
                    placeholder="password 123"
                    className="bg-[#F5F5F5] text-sm w-full py-4 px-4 outline-none rounded-full focus:ring-primary focus:ring-2 "
                  />
                </div>
                <div className="flex justify-between w-full mb-8">
                  <ul className="unstyled centered">
                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-1"
                        type="checkbox"
                        value="value1"
                      />
                      <label
                        htmlFor="styled-checkbox-1"
                        className=" text-black text-md font-semibold text-center"
                      >
                        Remember Me
                      </label>
                    </li>
                  </ul>

                  {/* jdnwjfnj */}
                    <a href="#" className="text-primary text-sm font-semibold">
                      {" "}
                      Forget Password?
                    </a>
                </div>
                  <a href="#" className="bg-primary text-black text-md font-semibold w-full text-center  rounded-full py-2">
                    {" "}
                    Login
                  </a>
              </form>
              <div className="flex justify-between w-full ">
                <div className="flex items-center gap-2">
                    <a href="#" className="text-sm font-semibold">Not registered? </a>
                    <a href="#" className="text-primary text-sm font-semibold">
                      Sign Up
                    </a>
                </div>
                  <a href="#" className="text-primary text-sm font-semibold">
                    {" "}
                    Privacy Policy
                  </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
